import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/11/1.gif'
import img1 from '../../assets/blog/11/2.jpg'
import img2 from '../../assets/blog/11/3.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Proč jsou ženy pořád uštvané?" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Proč jsou ženy pořád uštvané?" />
        <ContentSubPage>
          <p>
            Co znamená výraz &#8222;druhá směna&#8220; dnes asi všichni víme.
            Poté, co se pracující matka vrátí ze zaměstnání, případně zakončí
            pracovní den na home office, čeká ji práce v domácnosti a s dětmi.
            Už poměrně dlouho se mluví a píše o tom, že se tato práce má dělit
            mezi oba rodiče. Matky nemají tajné zaklínadlo na prodloužení dne
            ani lektvar proti únavě. Domácí směna taky není nijak specificky
            „ženská”, obě pohlaví ji mohou zvládnout stejně dobře.
          </p>
          <p>
            Situace se také v tomto ohledu postupně lepší. Dnes už naštěstí není
            žádnou výjimkou potkat na hřišti či nákupech otce s dětmi, muži se
            také ve větší míře stavějí čelem k domácím pracem. Statisticky sice
            stále ještě nejsme na ideálním rozdělení fifty-fifty, ale málokterý
            muž už se zaštítí formulkou „já se starám o auto“ a ženy se naučily
            říkat si o pomoc. Přesto bývají matky pořád více uhoněné, naštvané
            nebo zoufalé, než bychom tím pádem chtěli a čekali. Podle dva roky
            starého amerického výzkumu právě ony kolabují z vyčerpání či
            vyhoření o třetinu častěji než otcové. A patrně už známe důvod. Jde
            o tzv. mentální zátěž.
          </p>
          <p>
            Jednoduše řečeno: to, že si žena řekne o pomoc například s nákupem,
            totiž znamená, že musela naplánovat, co se bude vařit, sepsat
            nákupní seznam, myslet na to, kdy je třeba nákup udělat a
            komunikovat tuto potřebu s partnerem (což může být mimo jiné i výkon
            hodný psychologa a diplomata v jednom). Následuje kontrola, zda bylo
            vše nakoupeno, jak mělo, případně nastane nepříjemné překvapení v
            průběhu vaření a následné řešení krizové situace. Není náhoda, že
            často slyšíme: „Nejjednodušší je, když to udělám sama.“ A když jde o
            návštěvy lékařů s dětmi, případně komunikaci se školou, sdílení
            mentální zátěže by často znamenalo doslova celé školení. Není pak
            žádné překvapení, že v čekárnách a na rodičovských schůzkách bývá
            otec pořád vzácným jevem.
          </p>
          <p>
            Jak z toho ven? Úplně jednoduše to nepůjde. Je zkrátka třeba sdílet
            i mentální zátěž, tedy mluvit spolu. Je také důležité hlídat si čas
            pro sebe. Pokud jde partner dvakrát do týdne sportovat či na pivo,
            měla by mít podobný prostor i žena. Velkým pomocníkem mohou být i
            moderní technologie. Třeba aplikace Flagis vám pomůže nejen s
            plánováním a rozřazením úkolů, ale i s jejich sdílením, a to velmi
            přehledným a jednoduchým způsobem. Může tak pomoci odbourat
            nedorozumění, která při komunikaci zákonitě vznikají.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img1} target="_blank" rel="noreferrer">
              <img src={img1} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img2} target="_blank" rel="noreferrer">
              <img src={img2} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
